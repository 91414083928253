<template>
  <div class="">
    <div class="mr-6 my-3" dir="rtl">
      <swiper
        :grabCursor="true"
        :modules="modules"
        :slidesPerView="'auto'"
        :spaceBetween="20"
        :freeMode="true"
        :centeredSlides="false"
      >
        <swiper-slide v-for="track in tracks" :key="track.id">
          <collection-tracks  :track="track" :collectionId="collectionId"/>
        </swiper-slide>
        
      </swiper>
    </div>
  </div>
</template>
  <script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper";
import CollectionTracks from '../customTracks/CollectionTracks.vue';

export default {
  props: ["tracks","collectionId"],
  components: {
    Swiper,
    SwiperSlide,
    CollectionTracks,
  },
  setup() {
    return {
      modules: [Pagination],
    };
  },
  
};
</script>
  <style scoped>
.swiper {
  width: 100%;
  height: 270px;
}
@media only screen and (max-width: 960px) {
  .swiper {
    width: 100%;
    height: 190px;
  }
}
.swiper-slide {
  text-align: center;

  width: 194px !important;
  height: 100%;
  /* Center slide text vertically */
}

@media only screen and (max-width: 960px) {
  .swiper-slide {
    text-align: center;

    width: 120px !important;
    height: 100%;
    /* Center slide text vertically */
  }
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: none;
  object-fit: fill !important;
}
</style>
  