<template>
  <div
    class="flex items-center space-x-4"
    v-for="(audio, indexo) in audios.slice(index, index + 1)"
    :key="indexo"
  >
    <div class="">
      <p class="text-xs text-gray-200 text-right">
        {{ audio.name_ar }}
      </p>
      <p class="text-gray-400">{{ artist ? artist.name_ar: audio.artist.name_ar }}</p>
    </div>
    <img class="w-16 h-16 rounded-md" :src="getImage(artist,audio)" :alt="artist ? artist.name_ar: audio.artist.name_ar" width="100" height="100" />
  </div>
</template>

<script>
export default {
    props: ["audios", "artist", "index"],
  data() {
    return {
      https: "https://systech-abrof.s3.amazonaws.com/artists/",
    };
  },
  methods: {
    getImage(artist,audio) {
      var images;
      if(audio&& audio.artist&&audio.artist.cover){
        return this.https + audio.artist.cover;
     
      }else if (artist.cover) {
        return this.https + artist.cover;
      } else {
         images = require.context("@/assets/logo", false, /\.svg$/);
        return images("./" + "main.svg");
      }
    },
  },
};
</script>

<style>
</style>