<template>
  <div>
    <img
              src="@/assets/logo/abrof-logo.svg"
              class="object-cover"
              alt="logo"
              width="100"
              height="100"
            />
            <img
              src="@/assets/logo/abrof-slogan.svg"
              class="object-cover"
              alt="logo"
              width="100"
              height="100"
            />
  </div>
</template>

<script >

</script>

<style>

</style>