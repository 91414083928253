<template>
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="4"
      stroke="#C43670"
      class="md:w-6 md:h-6 h-4 w-4 mr-1 mt-1"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M15.75 19.5L8.25 12l7.5-7.5"
      />
    </svg>
  </div>
</template>

<script lang="ts" setup>
</script>

<style>
</style>