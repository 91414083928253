<template>
  <div class="flex justify-between items-center cursor-pointer">
    <div class="flex items-center">
      <div
        class="
          rounded-md
          md:h-24 md:w-24
          h-[60px]
          w-[60px]
          m-1
          bg-shimmer
          duration-400
          animate
          shimmer
        "
      ></div>
      <div class="block m-2 mx-2">
        <p class="text-lg h-3 w-12 bg-shimmer duration-400 animate shimmer my-2 rounded-md"></p>
        <p
          class="
            text-sm text-gray-600
            h-3
            w-24
            bg-shimmer
            duration-400
            animate
            shimmer
            rounded-md
          "
        ></p>
      </div>
    </div>
    <div class="flex justify-between">
      <span
        class="
          w-3
          h-8
          mx-4
          lg:ml-6
          md:ml-1
          bg-shimmer
          duration-400
          animate
          shimmer
          rounded-md
        "
      ></span>
      <span
        class="w-3 h-8 bg-shimmer duration-400 animate shimmer rounded-md"
      ></span>
    </div>
  </div>
</template>
  
  <script>
export default {
  props: ["track"],
};
</script>
