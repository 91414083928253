<template>
  <!-- add to favorite action -->
  <div class=" hidden md:block relative">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :fill="isFavorite(currentTrack?.id) ? 'red' : 'none'"
      viewBox="0 0 24 24"
      stroke-width="1"
      :stroke="isFavorite(currentTrack?.id) ? 'red' : '#fff'"
      class="w-8 h-8 cursor-pointer "
      @click="addFavoriteTrack(currentTrack)"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
      />
    </svg>
  </div>
  <!-- end of add to favorite action  -->
  <!-- previous button action -->
  <div class="text-grey-100 hover:bg-gray-800 rounded-full p-1 hidden md:block">
    <svg
      @click="prevButton ? $emit('previous') : ''"
      class="w-10 h-10 cursor-pointer"
      fill="#fff"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
    >
      <path d="M4 5h3v10H4V5zm12 0v10l-9-5 9-5z" />
    </svg>
  </div>
  <!-- end of previous button action -->

  <!-- play and puse button actions -->
  <div
    class="text-grey-100 hover:bg-gray-800 rounded-full p-2 shadow-lg hidden md:block"
  >
    <div class="absolute mb-[36px] -mt-1.5 -ml-1.5 transform">
      <div
        :class="
          !loaded
            ? 'border-t-transparent border-dashed animate-spin rounded-full border-gray-400 border-4 h-[60px]  w-[60px] mb-12'
            : ''
        "
      ></div>
    </div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="#fff"
      v-if="!pauseTrack"
      @click="$emit('play', (stopPlaying = false))"
      class="w-12 h-12 cursor-pointer"
    >
      <path
        fill-rule="evenodd"
        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm14.024-.983a1.125 1.125 0 010 1.966l-5.603 3.113A1.125 1.125 0 019 15.113V8.887c0-.857.921-1.4 1.671-.983l5.603 3.113z"
        clip-rule="evenodd"
      />
    </svg>

    <svg
      v-else
      @click="$emit('pause')"
      class="w-12 h-12 cursor-pointer"
      fill="#fff"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
    >
      <path d="M5 4h3v12H5V4zm7 0h3v12h-3V4z" />
    </svg>
  </div>
  <!-- end of play and pause button actions -->

  <!-- next button action -->
  <div class="text-grey-100 hover:bg-gray-800 rounded-full p-1 hidden md:block">
    <svg
      @click="nextButton ? $emit('next') : ''"
      class="w-10 h-10 cursor-pointer"
      fill="#fff"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
    >
      <path d="M13 5h3v10h-3V5zM4 5l9 5-9 5V5z" />
    </svg>
  </div>
  <!-- end of next button actions -->

  <!-- add to playlist action -->
  <div class="relative hidden">
    <new-play-list
      :addPlayListForm="addPlayListForm"
      @close-add-new-playList="closeAddNewPlayList"
    />
    <div
      v-show="showMenu"
      class="absolute bg-gray-700 w-64 right-1 z-20 rounded-t-md"
      style="bottom: 4.5rem"
    >
      <div class="text-gray-2 text-lg text-right text-gray-200 m-2">
        <span
          class="flex justify-end space-between-3 cursor-pointer"
          @click="addNewPlayList"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="green"
            class="w-6 h-6 mx-2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          إضافة قائمة
        </span>
      </div>

      <div class="h-36 overflow-auto">
        <ul class="text-center text-gray-200 mx-3" v-show="getPlaylists">
          <li
            class="text-right text-md m-2 cursor-pointer"
            v-for="pls in getPlaylists"
            :key="pls.id"
            @click="addTrackToplayListAction(pls.id, trackIdFav)"
          >
            {{ pls.name }}
          </li>
        </ul>
      </div>
      <div class="flex justify-between mx-2 my-2">
        <button
          class="bg-red-600 hover:bg-red-500 text-white font-bold py-2 px-4 rounded"
          @click="hideMenu"
        >
          إلغاء
        </button>
        <button
          class="bg-green-600 hover:bg-green-500 text-white font-bold py-2 px-6 rounded"

          @click="hideMenu"
        >

          تم
        </button>
      </div>
    </div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1"
      stroke="#fff"
      class="w-8 h-8 cursor-pointer  "
      @click="showPlayListOptions"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
      />
    </svg>
  </div>
  <!-- end of add to playlist -->
</template>

<script>
import NewPlayList from "./NewPlayList.vue";
import { useFavoriteStore } from "@/stores/favorites";
import { useTracksStore } from "@/stores/tracks";
import { mapActions, mapState } from "pinia";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { toRef } from "vue";
import favoritesMixin from "@/mixins/favoritesMixin";
export default {
  mixins:[favoritesMixin],
  components: { NewPlayList },
  emits: ["play", "pause", "next", "previous"],
  props: ["nextButton", "pauseTrack", "prevButton", "loaded", "trackId","currentTrack"],
  setup(props) {
    const trackIdFav = toRef(props, "trackId");
    return { trackIdFav };
  },
  data() {
    return {
      showMenu: false,
      addPlayListForm: false,
      closeNewPlayListForm: false,
    };
  },

  computed: {
    ...mapState(useTracksStore, ["getPlaylists"]),
  },
  methods: {
    ...mapActions(useFavoriteStore, [
      "addFavoriteTrackAction",

      "addTrackToplayList",
    ]),
    ...mapActions(useTracksStore, ["getUserLibarary"]),
    async addTrackTofavorite(id) {
      await this.addFavoriteTrackAction(id);
      toast.success("تمت الاضافة", {
        position: toast.POSITION.TOP_CENTER,
      });
    },
    async addTrackToplayListAction(plsId, trackId) {
      await this.addTrackToplayList(plsId, trackId);
      toast.success("تمت الاضافة", {
        position: toast.POSITION.TOP_CENTER,
      });
      this.hideMenu();
    },
    checkresponse(response) {
      if (response.data) {
        toast.success(
          `تمت اضافة قائمة تشغيل باسم ${response.data.createPlaylist.name}`,
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
        this.$emit("close-add-new-play-list");
      } else {
        toast.error("حدث خطأ", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    },
    hideMenu() {
      this.showMenu = false;
      this.addPlayListForm = false;
    },
    showPlayListOptions() {
      this.showMenu = true;
    },
    addNewPlayList() {
      this.showMenu = false;

      this.addPlayListForm = true;
    },
    closeAddNewPlayList() {
      this.addPlayListForm = false;
    },
  },
  async created() {
    try {
      await this.getUserLibarary();
    } catch (error) {
      console.log(error);
    }
  },
};
</script>

<style>
</style>
