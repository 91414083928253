<template>
  <div
    v-show="addPlayListForm"
    class="absolute bottom-7 mx-1 bg-gray-700 right-1 w-64 z-20 rounded-t-md"
    style="bottom:4.5rem"
  >
    <div class="text-gray-2 text-lg text-right text-gray-200 m-2">
      <span
        class="flex justify-end space-between-3 cursor-pointer"
        @click="addNewPlayList"
      >
        إضافة قائمة جديدة
      </span>
    </div>
    <div class="my-1 flex justify-end m-2 mr-6">
      <div class="space-x-2 my-2">
        <label for="all" class="text-gray-200 flex justify-end my-1"
          >إسم القائمة</label
        >
        <input
          type="text"
          v-model="input.name"
          class="rounded-md py-2 w-full text-right px-2"
          placeholder="اسم القائمة"
        />
      </div>
    </div>
    <div class="my-1 flex justify-end m-2 mr-6">
      <div class="space-x-2">
        <label for="all" class="text-gray-200">عامة</label>
        <input  type="checkbox" id="checkbox" v-model="input.is_public" class="h-4 w-4" />
      </div>
    </div>
    <div class="flex justify-between mx-2 my-2">
      <button
        class="bg-red-600 hover:bg-red-500 text-white font-bold py-2 px-4 rounded"
        @click="$emit('close-add-new-play-list')"
      >
        إلغاء
      </button>
      <button
        class="bg-green-600 hover:bg-green-500 text-white font-bold py-2 px-6 rounded"
        @click="createPlayList"
      >
        تم
      </button>
    </div>
  </div>
</template>

<script>
import {useFavoriteStore} from '@/stores/favorites'
import { mapActions } from 'pinia';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
export default {

  emits: ["close-add-new-play-list"],
  props: ["addPlayListForm"],
  data(){
    return{
      input:{
        name:"",
        is_public:false
      }
      

    }
  },
  methods:{
    ...mapActions(useFavoriteStore,['createPlayListAction']),
   async createPlayList(){
        await this.createPlayListAction(this.input).then((response)=>this.checkresponse(response));
    },
    checkresponse(response){
      if(response.data){
        toast.success(`تمت اضافة قائمة تشغيل باسم ${response.data.createPlaylist.name}`, {
          position: toast.POSITION.TOP_CENTER,
        });
        this.$emit('close-add-new-play-list');
      }else{
        toast.error('حدث خطأ', {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  }
};
</script>

<style>
</style>