

import { defineStore } from "pinia";
import apolloClient from "../plugins/apollo";
import { homeData } from "@/gqls/colections";
export const useCollectionStore = defineStore("collection", {
    state: () => {
        return {

            collections: Object,
            tracks: Array,
            collectionId:null

        }
    },
    getters: {
        getHomeCollections: (state) => state.collections,
        getPageTracks: (state) => state.tracks,
        getCollectionId:(state)=>state.collectionId

    },
    actions: {
        async showHomeData() {

            await apolloClient
                .query({
                    query: homeData,

                    fetchPolicy: "cache-first",
                    variables: {
                        getAppearanceLayoutId: "home-page",
                    }
                }).then(async response => {
                    this.collections = response.data.getAppearanceLayout.collections
                   

                })
                .catch(error => {
                    this.error = error
                })
        },

        async showPageTracks(title) {
          await  this.showHomeData()
            this.tracks=[];
           await(this.collections.forEach((item)=>{
            if(item.title==title){
                this.tracks= [...item.tracks],
                this.collectionId=item.id
                
            }
           }))
              
            
            
        },

      

    }
})
