import { defineStore } from "pinia";
import apolloClient from "../plugins/apollo";
import { Getsearch } from "@/gqls/Search";
export const useSearchtStore = defineStore("search", {
    state: () => {
        return {
            artists: Array
        }
    },
    getters: {
        artistsData: (state) => state.artists,
    },
    actions: {
        async showSearchResults(page = 1, limit = 14,text="") {
            return new Promise( (resolve) => 
            {
             apolloClient
                .query({
                    query: Getsearch,
                    fetchPolicy: "cache-first",
                    variables: {
                        page: page,
                        limit: limit,
                        text:text
                    }
                }).then(async response => {
                    this.artists = response.data.searchForArtists.data,
                 
                    resolve(response)
                })
            })
        },
    

    }
})
