
import gql from "graphql-tag";

export const getTracks = gql`
query GetTracks($page: Int!, $limit: Int!) {
  getTracks(page: $page, limit: $limit) {
    data {
      order
      id
      full_url
      name_ar
      single
      lyrics
      is_published
      artist {
        id
        cover
        name_ar
      }
    }
  }
}
  
`;


export const getTrack = gql`
query GetTrack($getTrackId: ID!) {
  getTrack(id: $getTrackId) {
    id
    name_ar
    full_url
    description_ar
    order
    single
    lyrics
    artist {
      id
      name_ar
      cover
      tracks {
        id
        name_ar
        full_url
        description_ar
        order
        single
        lyrics
        is_published
        
      }
    }
  }
}  
`;


