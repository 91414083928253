<template>
    <div class="">
      <div class="mr-6 my-3" dir="rtl">
        <swiper
          :grabCursor="true"
          :modules="modules"
          :slidesPerView="'auto'"
          :spaceBetween="20"
          :freeMode="true"
          :centeredSlides="false"
        >
          <swiper-slide v-for="artist in artists" :key="artist.id" ><cat-rounded :artist="artist"/></swiper-slide>
          

        </swiper>
      </div>
    </div>
  </template>
    <script>
  // Import Swiper Vue.js components
  import { Swiper, SwiperSlide } from "swiper/vue";
  
  // Import Swiper styles
  import "swiper/css";
  
  import "swiper/css/pagination";
  
  // import required modules
  import { Pagination } from "swiper";
import CatRounded from '../cards/CatRounded.vue';
  
  export default {
    props:['artists'],
    components: {
      Swiper,
      SwiperSlide,
 
        CatRounded,
    },
    setup() {
      return {
        modules: [Pagination],
      };
    },
  };
  </script>
    <style scoped>
  .swiper {
    width: 100%;
    height: 183px;
  }
  @media only screen and (max-width: 960px) {
    .swiper {
      width: 100%;
      height: 125px;
    }
  }
  .swiper-slide {
    text-align: center;
  
    width: 194px !important;
    height: 100%;
    /* Center slide text vertically */
  }
  
  @media only screen and (max-width: 960px) {
    .swiper-slide {
      text-align: center;
  
      width: 95px !important;
      height: 100%;
      /* Center slide text vertically */
    }
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    -o-object-fit: none;
    object-fit: fill !important;
  }
  </style>
    u