import { defineStore, acceptHMRUpdate } from "pinia";
import apolloClient from "../plugins/apollo";
import { addRecentPlayedTrack } from "@/gqls/mutations";
import { getRecentsTracks } from "@/gqls/auth";

export const useRecentStore = defineStore("recents", {
    state: () => ({
        recents: [], // Array to store favorite tracks
    }),

    getters: {
        /**
         * Getter to retrieve all favorite tracks
         */
        getRecentsTracks: (state) => state.recents,

    },

    actions: {
        /**
         * Add a single track to favorites
         * @param {Object} track - The track object of the track to be added
         */
        async addRecentTrackAction(track) {
            let trackId = track.id;
            
            return new Promise((resolve, reject) => {
                apolloClient
                    .mutate({
                        mutation: addRecentPlayedTrack,
                        variables: { trackId },
                    })
                    .then((response) => {
                        // Extract the recents from the response
                        let newTrack = response.data.addRecentTrack.recentTrack;



                        // Add the track to the recents array
                        this.recents = [...this.recents, newTrack];

                        console.log("Added to recents:", newTrack);
                        resolve(response);
                    })
                    .catch((error) => {
                        console.error("Error adding to recents:", error);
                        reject(error);
                    });
            });
        }
        ,



        /**
         * Fetch user recents tracks from the server
         * @param {Number} page - The page number for paginated results (default 1)
         * @param {Number} limit - The number of results per page (default 20)
         */
        async fetchRecentsAction(page = 1, limit = 6) {
            return new Promise((resolve, reject) => {
                apolloClient
                    .query({
                        query: getRecentsTracks,
                        fetchPolicy: "network-only",
                        variables: { page, limit },
                    })
                    .then((res) => {
                        this.recents = res.data.getUserRecentTracks.data; // Assuming `data` contains the array of tracks
                        console.log("Fetched favorites:", this.recents);
                        resolve(this.favorites);
                    })
                    .catch((error) => {
                        console.error("Error fetching recents:", error);
                        reject(error);
                    });
            });
        },
    },
});

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept(acceptHMRUpdate(useRecentStore, import.meta.webpackHot));
}
