import gql from "graphql-tag";

export const getArtists = gql`

query getArtists ($page: Int!, $limit: Int!){
 
 getArtists (page: $page, limit: $limit){
   data {
     cover
     id
     name_ar
     desc
   }
   meta {
    total
    next_page_url
    current_page 
   }
 }
}
`;



export const getArtist = gql`
query GetTracks($getArtistId: ID!) {
  getArtist(id: $getArtistId) {
    id
    name_ar
    name_en
    popularity
    desc
    cover
    genre_id
    createdAt
    updatedAt
    tracks {
      id
      name_ar
      full_url
      description_ar
      order
      lyrics
      is_published
      single
    }
  }
}
`;