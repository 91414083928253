<template>
  <div class="text-green-400 text-center text-xl">
    <p class="text-white text-xl font-bold " v-if="!internalError">...الرجاء الانتظار </p>
    <p class="text-white text-xl font-bold " v-if="message!=''"> {{ message }} </p>

    <h1 class="text-gray-500 text-4xl text-center mt-12" v-if="internalError">500|Server</h1>

  </div>
</template>

<script>
import { useAuthStore } from '@/stores/auth';
import { mapActions, mapState } from 'pinia';
export default {
  data() {
    return {
      internalError: false,
      message:''
    }
  },
  mounted() {
    const script = document.createElement('script')
    script.id = 'gtm-script' // Add an id to the script tag

    script.id = 'gtm-script'
    script.innerHTML = `
      (function(w,d,s,l,i){
        w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});
        var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
        j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;
        f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-W4LQ5LV9');
    `
    document.head.appendChild(script)
  },
  beforeUnmount() {
    const script = document.head.querySelector('#gtm-script') // Use the id to select the script
    if (script) {
      document.head.removeChild(script)
    }
  },


  computed: {
    ...mapState(useAuthStore, ['isAuthenticated']),
  },
  methods: {
    ...mapActions(useAuthStore, ["checkSubescriptionStatus"]),
    checkResponse(response) {
      const { message, code } = response.data.checkSubscription;
      this.message=message =="User don't have active subscrption" ? "أنت غير مشترك في الخدمة":""
      console.log(message,code)
      if (message === "The token invalid or expired, try to re login.") {
        this.internalError = true;
      } else if (code === "101") {
        
        setTimeout(() => {
            this.$router.push({ name: "home" });
          }, 6000)

      }
    },
    async checkSubscriptionStatusAndRedirect() {
      try {
        const response = await this.checkSubescriptionStatus(this.$route.params.msisdn);
        this.checkResponse(response);
        if (localStorage.getItem("status")) {
          setTimeout(() => {
            this.$router.push({ name: "home" });
          }, 6000);
        }
      } catch (error) {
        console.error(error);
      }
    }
  },
  created() {
    this.checkSubscriptionStatusAndRedirect();
  }


}
</script>

<style></style>