<template>
    <ul
              class="w-full overflow-auto mb-2 pt-2"
              style="max-height: 100%"
              id="journal-scroll"
              dir="rtl"
            >
              <li
                @click="$emit('selectSound',indexo)"
                :style="indexo == index ? '' : ''"
                class="
                  flex
                  justify-between
                  items-center
                  py-1
                  rounded
                  cursor-pointer
                  w-11/12
                  mr-4
                "
                v-for="(audio, indexo) in audios"
                :key="indexo"
              >
                <div class="w-1/5 font-semibold m-auto">
                  <div class="">
                    <img
                      class="rounded-md h-[60px] w-[60px] m-1"
                      :src="getImage(artist,audio)"
                      alt="Sunset in the mountains"
                      width="100"
                      height="100"
                    />
                  </div>
                </div>
                <div class="w-3/5 font-semibold text-left m-auto">
                  
                  <div class="font-semibold text-sm">
                    <sound-animation class="mx-4 -mt-2" :state="state.audioPlaying[indexo]"/>
                    <p class="text-white">{{ audio.name_ar }}</p>
                  </div>
                </div>
                <div class="w-1/5 m-auto">
                  <svg
                    v-if="state.audioPlaying[indexo]"
                    class="w-6 h-6 m-auto"
                    fill="white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M5 4h3v12H5V4zm7 0h3v12h-3V4z" />
                  </svg>
                  <svg
                    v-else
                    class="w-6 h-6 m-auto"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="white"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
                    />
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
               
              </li>
            </ul>
</template>

<script>
import SoundAnimation from './SoundAnimation.vue';
export default {
    props:['index','audios','state','artist'],
    emits: ['selectSound'],
    data() {
    return {
      https: "https://systech-abrof.s3.amazonaws.com/artists/",
    };
  },
  components:{SoundAnimation},
  methods: {
    getImage(artist,audio) {
      var images;
      if(audio&& audio.artist&&audio.artist.cover){
        return this.https + audio.artist.cover;
     
      }else if (artist.cover) {
        return this.https + artist.cover;
      } else {
         images = require.context("@/assets/logo", false, /\.svg$/);
        return images("./" + "main.svg");
      }
    },
  },

}
</script>

<style>

</style>