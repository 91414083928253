<template>
  <div class="flex justify-between text-sm text-grey-darker font-semibold mt-4">

    <p class="text-white">{{ timer }}</p>
    <p class="text-white">{{ duration }}</p>

  </div>
</template>

<script>
export default {
    props:["duration","timer"]
};
</script>

<style>
</style>