<template>
  <div class="rounded-full px-1 w-1/3 lg:my-4 lg:px-1.5 lg:w-1/3 md:w-1/2 h-full my-6">
    <router-link :to="`/artist/show/${artist.id}`">
      <img
        ref="artistImage"
        class="rounded-full w-full h-full object-cover"
        :src="defaultImage"
        :data-src="getImage(artist)"
        :alt="artist.name_ar"
        width="100"
        height="100"
      />

      <div class="flex justify-center pt-4">
        <span
          class="inline-block text-gray-500 text-center md:text-xl text-md truncate font-bold"
        >{{ artist.name_ar }}</span
        >
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: ['artist'],
  data() {
    return {
      https: 'https://systech-abrof.s3.amazonaws.com/artists/',
      defaultImage: require('@/assets/playlists/1.jpg'),
      observer: null,
    };
  },
  mounted() {
    this.observer = new IntersectionObserver(this.handleIntersection, {
      root: null,
      rootMargin: '0px',
      threshold: 0.5, // Adjust as needed
    });

    this.observer.observe(this.$refs.artistImage);
  },
  methods: {
    getImage(artist) {
      if (artist.cover) {
        return this.https + artist.cover;
      } else {
        var images = require.context('@/assets/logo', false, /\.svg$/);
        return images('./' + 'main.svg');
      }
    },
    handleIntersection(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const img = entry.target;
          img.src = img.dataset.src;
          this.observer.unobserve(img);
        }
      });
    },
  },
};
</script>

<style>
</style>
