<template>
  <div class="px-1 w-1/3 lg:my-4 lg:px-4 lg:w-1/6 md:w-1/5 sm:w-1/4 my-3">
    <div
      class="md:w-[186px] md:h-[190px] w-[115px] h-[120px] bg-gray-300 duration-400 animate shimmer rounded-t-md"
    ></div>
    <div class="h-full">
      <div class="flex justify-start pt-4 my-1">
        <span
          class="inline-block px-1 text-gray-100 text-right mr-2 md:text-xl text-lg font-bold truncate bg-gray-100 h-4 w-16 rounded-md bg-shimmer duration-400 animate shimmer"
        >
        </span>
      </div>
      <div class="flex justify-start pt-4 my-1">
        <span
          class="inline-block px-1 text-gray-100 text-right mr-2 md:text-xl text-lg font-bold truncate bg-gray-100 h-4 w-16 rounded-md bg-shimmer duration-400 animate shimmer"
        >
        </span>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {};
</script>
