import gql from "graphql-tag";


export const homeData = gql`
query getAppearanceLayout($getAppearanceLayoutId: String) {
  getAppearanceLayout(id: $getAppearanceLayoutId) {
    id
    collections {
    id
      addition_type
      title
      content_type
      artists {
        cover
        id
        name_ar
        desc
      }
 tracks {
         id
      name_ar
      full_url
      description_ar
      order
      is_published
      lyrics
      single
            artist{
            id
            cover
            name_ar
            }
      

      }
     
    }
  }
}

`