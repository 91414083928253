import { defineStore } from "pinia";
import apolloClient from "../plugins/apollo";
import { getGenres,getGenre, getCats } from "@/gqls/genres";
export const useGenresStore = defineStore("genres", {
    state: () => {
        return {
            genres: Array,
            genre:Object,
            genreTracks:Array,
            genreArtists:Array,
            cats:Object,
            meta:Object,


        }
    },
    getters: {
        genresData: (state) => state.genres,
        genreData:(state)=>state.genre,
        getGenreTracks:(state)=>state.genreTracks,
        getGenreArtists:(state)=>state.genreArtists,
        getCatsData:(state)=>state.cats,
        getMeat:(state)=>state.meta

    },
    actions: {
        async showGenresData() {

            await apolloClient
                .query({
                    query: getGenres,

                    fetchPolicy: "cache-first",
                    variables: {

                        page: 1,
                        limit: 14

                    }
                }).then(async res => {
                    this.genres = [...res.data.getGenres.data.filter((genre)=>genre.is_published===true)]
                  

                })
                .catch(error => {
                    this.error = error
                })
        },
        async showGenre(id) {
            this.genre=[];
            this.genreTracks=[];
            this.genreArtists=[];
            await apolloClient
                .query({
                    query: getGenre,

                    fetchPolicy: "cache-first",
                    variables: {

                        getGenreId:id

                    }
                }).then(async res => {
                    this.genre = res.data.getGenre,
                    this.genreTracks=res.data.getGenre.tracks.slice(0,5),
                    this.genreArtists=res.data.getGenre.artists.slice(0,6)
                   
                   
                })
                .catch(error => {
                    this.error = error
                })
        },

        async showCatTracks(page=1,limit=4){
            await apolloClient
            .query({
                query: getCats,

                fetchPolicy: "cache-first",
                variables: {

                    page: page,
                        limit: limit*page

                }
            }).then(async (res) => {
            this.cats=res.data.getGenres.data.map((item)=>({
                ...item,
                tracks: item.tracks.slice(0,10)
            })) ,
            this.meta=res.data.getGenres.meta   
           
            })
            .catch(error => {
                this.error = error
            })
        }

    }
})
