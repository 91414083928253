
import gql from "graphql-tag";
/**
 * get  Play Lists 
 */

export const getPlaylists = gql`
query GetPlaylists($page: Int, $limit: Int) {
  getPlaylists(page: $page, limit: $limit) {
    data {
      id
      user_id
      name
     
    }
    msg
    error
    meta {
      total
    }
  }
}
`


/**
 * get  playlist tracks
 */
export const  getPlayListTracks= gql`
query GetPlaylists($getPlaylistId: ID!) {
  getPlaylist(id: $getPlaylistId) {
    id
    name
    is_public
    tracks {
        order
      id
      full_url
      name_ar
      single
      lyrics
      is_published
      artist {
        id
        cover
        name_ar
   }
  }
}
}
`



/**
 * get all playlist tracks
 */


export const getallPlayListTracks=gql`
query GetAllPlaylistTracks($playlistId: ID!, $input: paginationInput) {
  getAllPlaylistTracks(playlistId: $playlistId, input: $input) {
    data {
      id
      name_ar
      full_url
      description_ar
      artist {
        id
        name_ar
        name_en
        cover
        tracks {
          id
          name_ar
          lyrics
          full_url
          is_published
         
        }
      }
    }
  }
}
`