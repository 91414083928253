


<template>
  <div
    class="hidden md:block flex flex-col min-h-screen px-0 overflow-y-auto relative custom-width"
    style="background-color: #232b36"
  >
    <div
      class="text-gray-100 text-xl bg-gradient-to-l from-gray-700 via-gray-900 to-black hidden md:block nav-custom-bg"
     
    >
      <div class="h-56 mt-1 flex items-center justify-center">
        <div class="block">
          <router-link to="/">
            <logo-images />
          </router-link>
        </div>
      </div>
    </div>
    <div class="">
      <ul class="mt-6 divide-gray-600" dir="rtl">
        <li class="flex text-white divide-y-200">
          <div
            :class="currentRouteName == 'home' ? 'bg-nava-a' : ''"
            class="list-item"
            style="height: 50px"
          ></div>
          <router-link to="/" class="flex space-x-4 px-3 py-4">
            <explore-svg :currentRouteName="currentRouteName" />

            <span
              class="span-title"
              :class="currentRouteName == 'home' ? 'text-nav-a' : ''"
              >اكتشف</span
            >
          </router-link>
        </li>
        <hr class="w-10/12 mr-4" />
        <li class="flex items-center px-0 text-white p-1">
          <div
            :class="currentRouteName == 'categories' ? 'bg-nava-a' : ''"
            class="list-item"
            style="height: 50px"
          ></div>
          <router-link to="/categories" class="flex space-x-4 px-4">
            <cat-svg :currentRouteName="currentRouteName" />

            <span
              class="span-title"
              :class="currentRouteName == 'categories' ? 'text-nav-a' : ''"
              >التصنيفات</span
            >
          </router-link>
        </li>
        <hr class="w-10/12 mr-4" />
        <li class="flex items-center px-0 text-white p-1">
          <div
            :class="currentRouteName == 'artists' ? 'bg-nava-a' : ''"
            class="list-item"
            style="height: 50px"
          ></div>
          <router-link to="/artists" class="flex space-x-4 px-4">
            <artist-svg :currentRouteName="currentRouteName" />

            <span
              class="span-title"
              :class="currentRouteName == 'artists' ? 'text-nav-a' : ''"
              >الفنانون</span
            >
          </router-link>
        </li>
        <hr class="w-10/12 mr-4" />
        <li
          v-if="getStatus"
          class="flex items-center px-0 text-white p-1 "
        >
          <div
            :class="currentRouteName == 'libarary' ? 'bg-nava-a' : ''"
            class="list-item"
            style="height: 50px"
          ></div>
          <router-link
            v-show="getStatus"
            to="/libarary"
            class="flex space-x-1 px-4"
          >
            <lib-svg :currentRouteName="currentRouteName" />

            <span
              class="span-title"
              :class="currentRouteName == 'libarary' ? 'text-nav-a' : ''"
              >مكتبتي</span
            >
          </router-link>
        </li>
        <hr class="w-10/12 mr-4" />
        <li class="flex items-center px-0 text-white p-1">
          <div
            :class="currentRouteName == 'profile' ? 'bg-nava-a' : ''"
            class="list-item"
            style="height: 50px"
          ></div>
          <router-link to="/profile" class="flex space-x-1 px-4">
            <settings-svg :currentRouteName="currentRouteName" />

            <span
              class="span-title"
              :class="currentRouteName == 'profile' ? 'text-nav-a' : ''"
              >الإعدادات</span
            >
          </router-link>
        </li>
        <hr class="w-10/12 mr-4" />
      </ul>

      <div class="hidden md:block" dir="rtl">
        <div class="absolute bottom-0 left-0 w-full divide-y divide-gray-700">
          <div
            class="block sec-color"
            v-if="
              getStatus == 'undefined' ||
              getStatus == false ||
              getStatus == null
            "
          >
            <div class="h-16 flex justify-center items-center text-center">
              <p class="text-gray-100 lg:px-6 md:px-2 md:text-sm lg:text-md">
                اشترك الآن واستمتع بمزيج من أجمل الأغاني السودانية، اسمع كل جديد
                وحصري الفن السوداني باشتراك واحد وبدون إعلانات
              </p>
            </div>
            <router-link to="/subscribe">
              <button
                class="primary-color rounded-[25px] py-1 px-0 mb-4 mx-4 w-24 text-white"
              >
                إشترك الأن
              </button>
            </router-link>
          </div>

          <div class="h-24 flex justify-center items-center">
            <p class="text-gray-600 text-md">© 2022 الحقوق محفوظة ل أبروف</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useAuthStore } from "@/stores/auth";
import { mapActions, mapState } from "pinia";
import ExploreSvg from "../svg/navbar/ExploreSvg.vue";
import CatSvg from "../svg/navbar/CatSvg.vue";
import ArtistSvg from "../svg/navbar/ArtistSvg.vue";
import LibSvg from "../svg/navbar/LibSvg.vue";
import SettingsSvg from "../svg/navbar/SettingsSvg.vue";
import LogoImages from "../svg/navbar/LogoImages.vue";

export default {
  components: {
    ExploreSvg,
    CatSvg,
    ArtistSvg,
    LibSvg,
    SettingsSvg,
    LogoImages,
  },
  data() {
    return {
      showSubscribtionMod: false,
      showSettings: true,
      menuItems: ["/", "/artists", "/categoreis", "/libarary"],
    };
  },
  computed: {
    ...mapState(useAuthStore, ["getStatus"]),
    currentRouteName() {
      return this.$route.name;
    },
  },

  watch: {
    getStatus: {
      immediate: true,
      deep: true,
      /* eslint-disable */
      handler(newValue, oldValue) {},
    },
  },

  methods: {
    ...mapActions(useAuthStore, [
      "subscribeAction",
      "checkSubescriptionStatus",
    ]),
    async subscribe() {
      await this.subscribeAction();
    },
  },
  created() {
    this.currentRouteName;
  },
};
</script>

<style scoped>
.sec-color {
  background-color: #72256d;
}

.primary-color {
  background-color: #c43670;
}

.custom-width {
  width: 22.6%;
}

@media screen and (max-width: 1300px) {
  .custom-width {
    width: 30.6%;
  }
}
</style>