<template>
 <div class="flex items-center space-x-4 ">
    <h2
    class="
     
      md:text-2xl
      text-lg
      font-bold
      text-white
      h-4
      w-32
      mx-2
      rounded-md
      bg-shimmer
      duration-400
      animate
      shimmer
    "
  >
    
  </h2>
  <span
      class="
        
        h-5
        w-5
        rounded-full
        mx-4
        mt-1
        bg-shimmer
        duration-400
        animate
        shimmer
        text-gray-100
      "
    >

    </span>
 </div>
</template>

<script>
export default {};
</script>
