<template>
  <div class="mb-0">
    <div v-if="showLyricsStatus">
      <div
        v-for="(audio, indexo) in audios.slice(index, index + 1)"
        :key="indexo"
        class=""
      >
        <div class="m-auto relative md:mt-24 mt-8">
          <img
            class="md:w-10/12 lg:h-96 w-full md:h-72 h-52 rounded-md block m-auto object-cover"
            :src="getImage(artist, audio)"
            :alt="audios.name_ar"
            style="height: 45dvh"
            width="100"
            height="100"
          />
        </div>
        <div class="mt-3">
          <h3 class="text-xl text-gray-100 text-center font-semibold">
            {{ artist ? artist.name_ar : audio.artist.name_ar }}
          </h3>
          <h3 class="text-xl text-gray-100 text-center font-semibold">
            {{ audio.name_ar }}
          </h3>
        </div>
      </div>
    </div>
    <div
      class="overflow-y-auto text-lg text-gray-300 leading-6 mt-12 px-3 text-right md:w-10/12 w-full h-[50dvh] my-3"
      v-else
    >
    <div  v-for="(audio, indexo) in audios.slice(index, index + 1)"
        :key="indexo">
   

      <div v-if="audio.lyrics">
       {{ audio.lyrics }}
      </div>
      <div v-else class="absolute top-1/3 left-1/2 -translate-x-1/2">
        <div>
          <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#C43670"
          data-slot="icon"
          class="w-36 h-36"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z"
          />
        </svg>
        <p  class="text-center text-lg font-bold">لا توجد كلمات</p>
        </div>
      </div>
    </div>
    </div>
    
  </div>
</template>

<script>
export default {
  props: ['audios', 'artist', 'index', 'showLyricsStatus'],
  data () {
    return {
      https: 'https://systech-abrof.s3.amazonaws.com/artists/'
    }
  },
  methods: {
    getImage (artist, audio) {
      var images
      if (audio && audio.artist && audio.artist.cover) {
        return this.https + audio.artist.cover
      } else if (artist.cover) {
        return this.https + artist.cover
      } else {
        images = require.context('@/assets/logo', false, /\.svg$/)
        return images('./' + 'main.svg')
      }
    }
  }
}
</script>

<style></style>
