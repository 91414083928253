
import gql from "graphql-tag";

export const Getsearch = gql`
query GetGenres($text: String!, $page: Int, $limit: Int) {
  searchForArtists(text: $text, page: $page, limit: $limit) {
    error
    msg
    data {
       cover
     id
     name_ar
 
    }
  }
}

`