import { defineStore,acceptHMRUpdate } from "pinia";
import apolloClient from "../plugins/apollo";
import { getTracks, getTrack } from "@/gqls/tracks";
import { getUserLib } from "@/gqls/auth";
import { getPlayListTracks } from "@/gqls/playList";
import { useCollectionStore } from "@/stores/collection";

export const useTracksStore = defineStore("tracks", {
    state: () => {
        return {
            tracks: Array,
            playedTracks: Array,
            track: Object,
            isPlaying: false,
            currentTrackId: 0,
            artist: Object,
            redirectUser: false,
            playLists: Array,
            favoriteTracks: [],
            favoritePlaylists: Array,
            followings: Array,
            playListItems: Object
        }
    },
    getters: {
        tracksData: (state) => state.tracks,
        trackData: (state) => state.track,
        getTracksToPlay: (state) => state.playedTracks,
        getIsPlaying: (state) => state.isPlaying,
        getCurrentId: (state) => state.currentTrackId,
        getArtistData: (state) => state.artist,
        getRedirectStatus: (state) => state.redirectUser,
        getFavoriteTracks: (state) => state.favoriteTracks,
        getFavoritePlayLists: (state) => state.favoritePlaylists,
        getFolloings: (state) => state.followings,
        getPlaylists: (state) => state.playLists,
        getPlayListData: (state) => state.playListItems



    },
    
    actions: {
        async showTracksData(page = 1, limit = 6) {
            let data = [];
            await apolloClient
                .query({
                    query: getTracks,

                    fetchPolicy: "cache-first",
                    variables: {

                        page: page,
                        limit: limit
                    },

                }).then(async res => {
                    data = res.data.getTracks.data,
                        data = [...data.filter((track) => track.is_published === true)],
                        this.tracks = data.map((track) => ({ ...track, howl: null }))


                })
                .catch(error => {
                    this.error = error
                })
        },
        async showTrack(id) {
            /* eslint-disable */
            let data = [];
            this.redirectUser = false;
            if (localStorage.getItem('status')) {
                this.redirectUser = false
                this.isPlaying = false;
                this.track = null;
                await apolloClient
                    .query({
                        query: getTrack,
                        variables: {
                            getTrackId: id
                        },
                        fetchPolicy: "cache-first",

                    }).then(async res => {
                        await (
                            data = res.data.getTrack.artist.tracks,
                            data = [...data.filter((track) => track.is_published === true)],
                            this.track = data.map((track) => ({ ...track, howl: null })),
                            this.currentTrackId = res.data.getTrack.id,
                            this.artist = res.data.getTrack.artist)

                    })
                    .catch(error => {
                        this.error = error
                    })
                if (this.track) {
                    this.isPlaying = true
                }
            } else {
                this.redirectUser = true;
            }
        },
        async getUserLibarary() {
            await apolloClient
                .query({
                    query: getUserLib,
                    fetchPolicy: 'network-only', 
                   
                }).then(async res => {
                    this.favoriteTracks = res.data.me.favorite_tracks,
                    this.playLists = [...res.data.me.playlists.filter((pls) => pls.is_public === false)],
                    this.favoritePlaylists = res.data.me.favorite_playlists,
                    this.followings = res.data.me.followings_list


                })
                .catch(error => {
                    this.error = error
                })
        },
        async getFavList(id) {
            this.redirectUser = false;
            this.isPlaying = false;
            if (localStorage.getItem('status')) {
                this.redirectUser = false

                this.track = null;
                let data = this.getFavoriteTracks;
                 (data = [...data.filter((track) => track.is_published === true)],
                    this.track = data.map((track) => ({ ...track, howl: null })))
                    this.playedTracks = data.map((track) => ({ ...track, howl: null }))
                this.currentTrackId = id;
                this.artist = null;
                if (this.track) {
                    console.log(this.track)
                    this.isPlaying = true
                }
            } else {
                this.redirectUser = true;
            }
            console.log( this.getFavoriteTracks,"getfavlist")


        },

        /***
         * get playlist tracks @param playlistId
         */
        async showPlayListTracks(playlistId) {
            /* eslint-disable */
            let data = [];
            this.redirectUser = false;
            if (localStorage.getItem('status')) {
                this.redirectUser = false

                this.track = null;
                await apolloClient
                    .query({
                        query: getPlayListTracks,
                        variables: {
                            getPlaylistId: playlistId
                        },
                        fetchPolicy: "cache-first",

                    }).then(async res => {
                        this.playListItems = res.data.getPlaylist;

                    })
                    .catch(error => {
                        this.error = error
                    })
                if (this.track) {

                }
            } else {
                this.redirectUser = true;
            }
        },
        /**
         * playing Tracks from playlist @param trackID @param playlistId
         */
        async playlistTracks(trackId, playlistId) {
            await this.showPlayListTracks(playlistId);


            let data = [];
            this.redirectUser = false;
            this.isPlaying = false;
            if (localStorage.getItem('status')) {
                this.redirectUser = false

                this.track = null;
                data = this.playListItems.tracks;
                if (data.length < 0) return;
                await (data = [...data.filter((track) => track.is_published === true)],
                    this.track = data.map((track) => ({ ...track, howl: null })))
                this.currentTrackId = trackId;
                this.artist = null;
                if (this.track) {

                    this.isPlaying = true
                }
            } else {
                this.redirectUser = true;
            }

        },

        /**
         * play from colections @param trackId,@param colectionID
         * 
         * 
         */

        async playcollection(trackId, collectionID) {

            let data = [];
            const colections = useCollectionStore();
            await colections.showHomeData();


            await (colections.collections.forEach((item) => {
                if (item.id == collectionID) {
                    data = [...item.tracks]
                }
            }));

       
         
            this.redirectUser = false;
            this.isPlaying = false;
            if (localStorage.getItem('status')) {
                this.redirectUser = false

                this.track = null;
               
                if (data.length < 0) return;
                await (data = [...data.filter((track) => track.is_published === true)],
                    this.track = data.map((track) => ({ ...track, howl: null })))
                this.currentTrackId = trackId;
                this.artist = null;
                if (this.track) {

                    this.isPlaying = true
                }
            } else {
                this.redirectUser = true;
            }
        },
        
    }

})

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept(acceptHMRUpdate(useTracksStore, import.meta.webpackHot))
  }