import { ApolloClient, createHttpLink, InMemoryCache, } from "@apollo/client/core";
import { setContext } from '@apollo/client/link/context';
// import { persistCache } from 'apollo-cache-persist'

// HTTP connection to the API
const httpLink = createHttpLink({
  uri: "https://graph.abrof.com/"

});



const authLink = setContext((_, { headers }) => {

  // get the authentication token from local storage if it exists

  const token = localStorage.getItem('token');

  // return the headers to the context so httpLink can read them

  return {

    headers: {

      ...headers,

      token:localStorage.getItem("token") ? token : "",

    }

  }

});


const apolloClient = new ApolloClient({

  link: authLink.concat(httpLink),

  cache: new InMemoryCache()

});

// persistCache({
//   cache,
//   storage: window.localStorage,
// });
// Create the apollo client
// const apolloClient = new ApolloClient({
//   link: httpLink,
// cache,
//   headers: {
//     token: localStorage.getItem('token')
//   }
// });

export default apolloClient;
