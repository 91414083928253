<template>
  <div
    class="
      md:w-[183px] md:h-[183px]
      w-[95px]
      h-full
      rounded-md
      overflow-hidden
      shadow-lg
    "
  >
    <div
      class="
        rounded-full
        w-full
        h-[95px]
        bg-shimmer
        duration-400
        animate
        shimmer
      "
    ></div>

    <p
      class="
        text-gray-300 text-sm
        mt-3
        h-3
        w-16
        bg-shimmer
        duration-400
        animate
        shimmer
        rounded-md
      "
    ></p>
  </div>
</template>
  
  <script>
export default {};
</script>
  
 