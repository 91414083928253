<template>
  <div class="md:flex md:justify-between">
    <!-- section start -->
    <!-- todo make it slots -->
    <div class="md:flex hidden text-white mx-1">
      <div
        class="bg-pink-600 w-12 rounded-md text-center mr-6 hidden"
        style="background-color: #72256d"
      >
        <span class="flex justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6 mt-[12px]"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0M3.124 7.5A8.969 8.969 0 015.292 3m13.416 0a8.969 8.969 0 012.168 4.5"
            />
          </svg>
        </span>
      </div>
      <div
        class="bg-pink-600 w-32 rounded-md text-center ml-3 btn-color"
        v-if="
          getStatus == 'undefined' || getStatus == false || getStatus == null
        "
      >
        <span
          class="flex justify-center text-center pt-3 cursor-pointer text-md"
          @click="subscribe"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6 mx-2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0M3.124 7.5A8.969 8.969 0 015.292 3m13.416 0a8.969 8.969 0 012.168 4.5"
            />
          </svg>
          إشتراك
        </span>
      </div>
      <div v-else>
        <div class="">
          <router-link to="/profile" class="flex space-x-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24 "
              stroke-width="1.5"
              stroke="#C43670"
              class="w-8 h-8 md:w-10 md:h-10 md:ml-0 ml-3"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
          </router-link>
        </div>
      </div>
      <div class="relative ml-12">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#C43670"
          class="w-6 h-6 absolute top-3 left-2 cursor-pointer"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
          />
        </svg>

        <input
          type="text"
          class="p-3 px-3 rounded-md w-80 bg-black outline-none border border-color"
          dir="rtl"
          placeholder="   البحث عن فنان"
          @focusout="handleChange"
        />
      </div>
    </div>
    <!-- todo make it slots -->
    <div class="text-white mr-3" dir="rtl">
      <!-- todo make it slots -->
      <span class="md:text-4xl text-2xl font-bold text-whtie">{{ title }}</span>
      <!-- todo make it slots -->
      <div class="hidden md:block border border-b-[4px] border-pink-600"></div>
    </div>
  </div>
</template>

<script>
import { useAuthStore } from "@/stores/auth";
import { mapActions, mapState } from "pinia";

export default {
  props: ["title"],
  emits: ["search"],
  computed: {
    ...mapState(useAuthStore, ["getStatus"]),
  },
  watch: {
    getStatus: {
      immediate: true,
      deep: true,
      /* eslint-disable */
      handler(newValue, oldValue) {},
    },
  },
  methods: {
    handleChange(event) {
      this.$emit("search", event.target.value);
    },

    ...mapActions(useAuthStore, [
      "subscribeAction",
      "checkSubescriptionStatus",
    ]),
    async subscribe() {
      await this.subscribeAction();
    },
  },
};
</script>

<style>
</style>