<template>
   <h2 class="flex md:text-2xl text-lg font-bold text-white" v-if="loaded">
        <router-link :to="`/show/catgories/${cat.id}`">  {{ cat.name_ar  ??"not yet"}} </router-link>
        <span class="">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="4"
            stroke="#C43670"
            class="md:w-6 md:h-6 h-4 w-4 mr-1 mt-1"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15.75 19.5L8.25 12l7.5-7.5"
            />
          </svg>
        </span>
      </h2>
</template>

<script>
export default {
props:['loaded','cat']
}
</script>

<style>

</style>