<template>
  <div class="flex justify-between items-center">
    <div
      class="flex items-center md:cursor-pointer"
      @click="fetchTracks(track.id)"
    >
      <div class="">
        <img
          ref="artistImage"
          class="rounded-md md:h-24 md:w-24 h-[60px] w-[60px] m-1"
          :src="defaultImage"
          :data-src="getImage(track)"
          :alt="track.name_ar"
          width="100"
          height="100"
        />
      </div>
      <div class="block m-2 mx-2">
        <p class="text-lg">{{ track.name_ar }}</p>
        <p class="text-sm text-gray-600" v-if="track.artist">
          {{ track.artist.name_ar }}
        </p>
      </div>
    </div>
    <div class="flex justify-between">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        :fill="isFavorite(track.id) ? 'red' : 'none'"
        viewBox="0 0 24 24"
        stroke-width="2.5"
        :stroke="isFavorite(track.id) ? 'red' : '#fff'"
        class="w-6 h-6 mx-4 lg:ml-6 md:ml-1 mt-2 cursor-pointer"
        @click="addFavoriteTrack(track)"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
        />
      </svg>
      <!-- start of card list options -->
      <div class="dropdown inline-block relative my-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="2.5"
          stroke="#fff"
          class="w-6 h-6 cursor-pointer nav"
          @click.prevent="showLists()"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
          />
        </svg>

        <ul
          class="dropdown-menu absolute left-3 text-gray-100 rounded-md p-2 w-64 z-30"
          v-show="toggel"
          style="background-color: #232b36"
        >
          <li
            class="flex space-x-2 rounded-t hover:bg-gray-700 py-2 px-4 whitespace-no-wrap cursor-pointer"
          >
            <router-link
              :to="`/artist/show/${track.artist.id}`"
              class="text-sm"
            >
              ذهاب للفنان</router-link
            >
          </li>
          <li class="" v-if="!shareOpen" @click="shareApi" id="share-1">
            <span
              class="flex space-x-2 rounded-t hover:bg-gray-700 py-2 px-4 whitespace-no-wrap cursor-pointer text-sm"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6 ml-2 svg-action"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M7.217 10.907a2.25 2.25 0 100 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186l9.566-5.314m-9.566 7.5l9.566 5.314m0 0a2.25 2.25 0 103.935 2.186 2.25 2.25 0 00-3.935-2.186zm0-12.814a2.25 2.25 0 103.933-2.185 2.25 2.25 0 00-3.933 2.185z"
                />
              </svg>

              مشاركة
            </span>
          </li>
          <li class="" v-if="shareOpen" @click="hideShareIcons" id="share-2">
            <span
              class="flex space-x-2 rounded-t card-bg hover:bg-gray-700 py-2 px-4 whitespace-no-wrap cursor-pointer text-sm"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6 ml-2 svg-action"
                id="share-cancel"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>

              مشاركة
            </span>

            <share-it :share="share" />
          </li>
        </ul>
      </div>
      <!-- end of card list options -->
    </div>
  </div>
</template>

<script>
import { useTracksStore } from "@/stores/tracks";
import { mapState, mapActions } from "pinia";
import ShareIt from "../partials/ShareIt.vue";
import { useFavoriteStore } from "@/stores/favorites";
// import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import favoritesMixin from "@/mixins/favoritesMixin";
export default {
  mixins:[favoritesMixin],
  props: ["track"],
  components: {
    ShareIt,
  },
  data() {
    return {
      https: "https://systech-abrof.s3.amazonaws.com/artists/",
      toggel: false,
      share: false,
      shareOpen: false,
      defaultImage: require("@/assets/playlists/1.jpg"),
      observer: null,
    };
  },
  computed: {
    ...mapState(useTracksStore, ["trackData"]),
    ...mapState(useFavoriteStore, ["isFavorite"]),
  },
  methods: {
    ...mapActions(useTracksStore, ["showTrack"]),
   
  
   
    async fetchTracks(id) {
      await this.showTrack(id);
    },
    async shareApi() {
      const title = window.document.title;
      const url = window.document.location.href;

      const text = `استمع لجميع أغاني الفنان ${this.track.artist.name_ar} على أبروف | سمع وشوف
`;
      const resultPara = null;
      const shareData = {
        title: title,
        text: text,
        url: url,
      };
      if (navigator.share) {
        try {
          await navigator.share(shareData);
          resultPara.textContent = "MDN shared successfully";
        } catch (error) {
          resultPara.textContent = `Error: ${error}`;
        }
      } else {
        this.shareOpen = true;
        return (this.share = true);
      }
    },
    hideShareIcons() {
      this.share = false;
      this.shareOpen = false;
    },
    showLists() {
      void 0;
      this.toggel = !this.toggel;
    },
    close(e) {
      if (!(this.$el.contains(e.target) || e.target.tagName == "SPAN")) {
        this.toggel = false;
        this.hideShareIcons();
      }
    },
    handleIntersection(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const img = entry.target;
          img.src = img.dataset.src;
          this.observer.unobserve(img);
        }
      });
    },
    getImage(track) {
      if (track.artist && track.artist.cover) {
        return this.https + track.artist.cover;
      } else {
        var images = require.context("@/assets/logo", false, /\.svg$/);
        return images("./" + "main.svg");
      }
    },
   
  },

  mounted() {
    this.observer = new IntersectionObserver(this.handleIntersection, {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, // Adjust as needed
    });

    this.observer.observe(this.$refs.artistImage);
    document.addEventListener("click", this.close);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.close);
  },
};
</script>

<style scoped>
.nav ul {
  display: none;
}

.nav ul {
  display: block;
}
.svg-action {
  pointer-events: none;
}
</style>
