
import gql from "graphql-tag";

export const getGenres = gql`
query getGenres ($page: Int!, $limit: Int!){
    getGenres(page: $page, limit: $limit) {
      data {
        id
        name_ar
        cover
        is_published
      }
    }
  }`;

export const getGenre = gql`
  query getGenre($getGenreId: ID!) {
  getGenre(id: $getGenreId) {
    id
    name_ar
   
    popularity
    order
    is_published
    cover
    tracks {
      id
      name_ar
      full_url
      description_ar
      order
      is_published
      single
      lyrics
      popularity
      artist {
        id
        name_ar
        cover
      }
      
    }
    artists {
      id
      name_ar
      popularity
      desc
      cover
    }
  }
  getPlaylists {
    data {
      id
      name
      is_public
      tracks {
        id
        name_ar
        full_url
        description_ar
        order
        is_published
        lyrics
        single
        popularity
        artist {
          id
          name_ar
          cover
        }
      }
    }
  }
}`;

/**
 * get tracks by genre for the home page infint scroll
 */
export const getCats=gql`
query GetGenres($page: Int, $limit: Int) {
  getGenres(page: $page, limit: $limit) {
    meta {
      current_page
      next_page_url
      total
    }
    data {
      id
      name_ar
      tracks {
       id
    name_ar
    full_url
    description_ar
    lyrics
    order
    single
    cover
    artist {
          cover
        }
      
      }
    }
  }
}
`