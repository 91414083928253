<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke="#fff"
    class="w-6 h-6 cursor-pointer"
    :class="hidePlayer ? '' : 'hidden'"
    @click="$emit('toggleDesktopPlayer')"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
    />
  </svg>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke="#fff"
    class="w-6 h-6 cursor-pointer"
    @click="$emit('toggleDesktopPlayer')"
    :class="hidePlayer ? 'hidden' : ''"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M4.5 15.75l7.5-7.5 7.5 7.5"
    />
  </svg>
</template>

<script>
export default {
  emits: ["toggleDesktopPlayer"],
  props: ["hidePlayer"],
};
</script>

<style>
</style>