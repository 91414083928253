<template>
  <div class="flex items-center space-x-2">
    <div class="h-full mx-2">
      <!-- play and puse button actions -->
      <div class="md:hidden">
        <div class="absolute mb-[36px] -mt-0.5 -ml-0.5 tranabsolutesform">
          <div
            :class="
              !loaded
                ? 'border-t-transparent border-dashed animate-spin rounded-full border-gray-400 border-4 h-[35px]  w-[35px] mb-12'
                : ''
            "
          ></div>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="#fff"
          v-if="!pauseTrack"
          @click="$emit('play', (stopPlaying = false))"
          class="w-8 h-8 cursor-pointer"
        >
          <path
            fill-rule="evenodd"
            d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm14.024-.983a1.125 1.125 0 010 1.966l-5.603 3.113A1.125 1.125 0 019 15.113V8.887c0-.857.921-1.4 1.671-.983l5.603 3.113z"
            clip-rule="evenodd"
          />
        </svg>

        <svg
          v-else
          @click="$emit('pause')"
          class="w-8 h-8 cursor-pointer"
          fill="#fff"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path d="M5 4h3v12H5V4zm7 0h3v12h-3V4z" />
        </svg>
      </div>
      <!-- end of play and pause button actions -->
    </div>
    <div class="flex items-center mx-2">
      <!-- add to favorite action -->
      <div class="">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          :fill="isFavorite(currentTrack?.id) ? 'red' : 'none'"
          viewBox="0 0 24 24"
          stroke-width="1"
          :stroke="isFavorite(currentTrack?.id) ? 'red' : '#fff'"
          class="w-6 h-6 cursor-pointer"
          @click="addFavoriteTrack(currentTrack)"
          
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
          />
        </svg>
      </div>
      <!-- end of add to favorite action  -->
    </div>
  </div>
</template>

<script>
import favoritesMixin from "@/mixins/favoritesMixin";
export default {
  mixins: [favoritesMixin],
  emits: ["play", "pause"],
  watch: {
    currentTrack(newVal) {
      console.log("Updated Current Track:", newVal);
    },
  },

  props: ["pauseTrack", "loaded", "currentTrack"],
  
};
</script>

<style>
</style>

