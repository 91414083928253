<template>
    <!-- add to favorite action -->
    <div class="hidden">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="white"
                    class="w-6 h-6 cursor-pointer mt-3 hidden"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
                    />
                  </svg>
                </div>
                <!-- end of add to favorite action  -->
                <!-- previous button action -->
                <div
                  class="text-grey-darker  rounded-full py-1"
                >
                  <svg
                    @click="prevButton ? $emit('previous') : ''"
                    class="w-10 h-10 cursor-pointer outline-none"
                    fill="white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M4 5h3v10H4V5zm12 0v10l-9-5 9-5z" />
                  </svg>
                </div>
                <!-- end of previous button action -->

                <!-- play and puse button actions -->
                <div class="text-grey-100  shadow-lg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="white"
                    v-if="!pauseTrack"
                    @click="$emit('play',stopPlaying = false)"
                    class="w-14 h-14 cursor-pointer outline-none"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm14.024-.983a1.125 1.125 0 010 1.966l-5.603 3.113A1.125 1.125 0 019 15.113V8.887c0-.857.921-1.4 1.671-.983l5.603 3.113z"
                      clip-rule="evenodd"
                    />
                  </svg>

                  <svg
                    v-else
                    @click="$emit('pause')"
                    class="w-14 h-14 cursor-pointer outline-none"
                    fill="white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M5 4h3v12H5V4zm7 0h3v12h-3V4z" />
                  </svg>
                </div>
                <!-- end of play and pause button actions -->

                <!-- next button action -->
                <div
                  class="text-grey-darker  rounded-full py-1"
                >
                  <svg
                    @click="nextButton ? $emit('next') : ''"
                    class="w-10 h-10 cursor-pointer outline-none"
                    fill="white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M13 5h3v10h-3V5zM4 5l9 5-9 5V5z" />
                  </svg>
                </div>
                <!-- end of next button actions -->

                <!-- add to playlist action -->
                <!-- <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="white"
                  class="w-6 h-6 cursor-pointer mt-3 hidden "
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                  />
                </svg> -->

                <!-- end of add to playlist -->
</template>

<script>
export default {
    emits:['play','pause','next','previous'],
props:['nextButton','pauseTrack','prevButton']
}
</script>

<style>
*:focus ,*:active ,*:focus{ outline: 0; }
</style>