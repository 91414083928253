<template>
  <!-- page container  -->
  <div>
    <div id="page-container  container mx-auto ">
      <!-- main contents wraper data goes here -->
      <div id="content-wrap  ">
        <div class="flex">
          <!-- start of main contents -->
          <div
            class="w-full h-full overflow-y-auto scrol-y-body invisible-scrollbar max-h"
            id="main"
          >
            <div class="w-full h-full mb-24 relative">
              <button
                class="px-2 py-1.5 rounded-md bg-pink-600 absolute top-3 left-24 z-40 text-gray-200"
                v-if="isRefresh"
                @click="update"
              >
                تحديث الصفخة
              </button>

              <!-- vue router full path -->
              <router-view :key="$route.fullPath" />
            </div>
          </div>

          <!-- end of main contents -->

          <!-- start of sidebar nav -->
          <nav-bar class="hidden" />

          <!-- end of sidebar nav -->
        </div>
        <div v-if="getIsPlaying && !getRedirectStatus && trackData.length > 0">
          <audio-player
            :audios="trackData"
            :currentINDEX="currentIndex"
            :artist="getArtistData"
            :siPlaying="getIsPlaying"
          />
        </div>
        <div v-show="getRouteName">
          <mobile-menu />
        </div>
      </div>
      <!-- footer slots -->

      <!-- end of footer slots -->
    </div>
  </div>
</template>
<script>
import AudioPlayer from "./components/player/AudioPlayer.vue";
import MobileMenu from "./components/partials/MobileMenu.vue";
import NavBar from "./components/partials/NavBar.vue";
import { useTracksStore } from "@/stores/tracks";
import { mapState, mapActions } from "pinia";
import { useFavoriteStore } from "./stores/favorites";
export default {
  components: {
    NavBar,
    MobileMenu,
    AudioPlayer,
  },
  data() {
    return {
      currentIndex: 2,
      registration: null,
      isRefresh: false,
      refreshing: false,
      showMobileMenu: true,
    };
  },
 
  computed: {
    //    .
    ...mapState(useFavoriteStore, ["getFavoriteTracks"]),
    ...mapState(useTracksStore, [
      "tracksData",
      "trackData",
      "getTracksToPlay",
      "getIsPlaying",
      "getCurrentId",
      "getArtistData",
      "getRedirectStatus",
      "getFavoriteTracks",
    ]),
    getRouteName() {
      if (this.$route.name === "login" || this.$route.name === "subscribe") {
        return false;
      } else {
        return true;
      }
      // return true
    },
  },
  watch: {
    getIsPlaying: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) {
          this.getCureentIndex(this.getCurrentId);
        }
      },
    },
    getRedirectStatus: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) {
          this.$router.push({ name: "subscribe" });
        }
      },
    },
  },
  methods: {
    ...mapActions(useTracksStore, ["showTracksData", "getUserLibarary"]),
    ...mapActions(useFavoriteStore, ["fetchFavoritesAction"]),
    getCureentIndex(getCurrentId) {
      this.currentIndex = this.trackData.findIndex(
        (track) => track.id == getCurrentId
      );
    },
    appUpdateUI(e) {
      this.registration = e.detail;
      this.isRefresh = true;
    },
    update() {
      this.isRefresh = false;
      if (this.registration || this.registration.waiting) {
        this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
      }
    },
  
  },
  async created() {
    try {
      await this.showTracksData();
      this.fetchFavoritesAction();
    } catch (e) {
      console.log(e);
    }
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      if (this.refreshing) return;
      this.refreshing = true;
      window.location.reload();
    });
  },
};
</script>

<style>
</style>
