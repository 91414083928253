<template>
  <div
    class="rounded-full md:w-[202px] md:h-[202px] w-[80px] h-[80px] m-2 my-6"
  >
    <div class="rounded-full bg-shimmer duration-400 animate shimmer md:w-[202px] md:h-[202px] w-[80px] h-[80px]"></div>
    <div class="flex justify-center pt-4">
      <span
        class="
          inline-block
          
          text-gray-500 
           text-center
          md:text-xl
          text-md
          truncate
          font-bold
          h-3
          w-24
          rounded-md
          bg-shimmer duration-400 animate shimmer
        "
      >
      </span>
    </div>
  </div>
</template>
    
    <script>
export default {
  props: ["artist"],
};
</script>
