<template>
    <div class="md:w-[183px] md:h-[183px] w-[95px] h-full rounded-md overflow-hidden shadow-lg ">
      <router-link :to="`/artist/show/${artist.id}`">
        <img
            class="rounded-full w-full h-[95px] object-cover"
        :src="getImage(artist)"
        alt="Sunset in the mountains"
        width="100"
        height="100"
      />
      </router-link>
      <p class="text-gray-300 text-sm mt-3">{{ artist.name_ar }}</p>
    </div>
  </template>
  
  <script>
  export default {
    props:["artist"],
    data(){
      return{
        https:'https://systech-abrof.s3.amazonaws.com/artists/'
      }
    },
    methods:{
      getImage(artist){
        if (artist.cover) {
        return this.https+artist.cover;
      } else {
        var images = require.context("@/assets/logo", false, /\.svg$/);
        return images("./" + "main.svg");
      }
      }
    }
  };
  </script>
  
  <style>
  </style>