<template>
  <div class="bluer">
    <div
      class="absolute top-0 w-full flex flex-col bg-gray-100 z-20 custom-width bg-[url('@/assets/Artists/jota.jpg')] bg-cover bg-center bg-origin-content backdrop-blur-xl"
      id="wrap"
      :class="toggle ? 'h-0' : 'h-full'"
      style="background-color: #232b36"
      :style="[{ 'background-image': 'url(' + getImage(artist) + ')' }]"
    >
      <!-- audio playlist body -->
      <div
        class="flex flex-col-2 justify-center w-full relative md:mt-0 h-full overflow-hidden"
        style="background: #151920 0% 0% no-repeat padding-box; opacity: 0.9"
        :style="[toggle ? { height: '11%' } : '']"
        v-if="!toggle"
      >
        <!-- audio playlist body -->
        <songs-list
          :audios="audios"
          :artist="artist"
          :index="index"
          :state="state"
          @select-sound="selectSound"
        />
        <!-- end of audio playlist   -->

        <!-- song inforation side for mobile and desktop -->
        <div
          class="flex flex-col w-full md:w-5/12 rounded-lg mx-4 md:mt-24 relative"
        >
          <!-- mobile song toggel -->
          <div v-show="!showLists" class="h-full overflow-auto relative">
            <mobilesong-toggel
              @toggleDesktopPlayer="toggleDesktopPlayer"
              @show-list="showList"
            />
            <xs-info :artist="artist" :audios="audios" :index="index"  :showLyricsStatus="showLyricsStatus"/>

            <!-- mobile player actions -->
            <div
              class="w-full mx-auto md:hidden px-8 absolute top-2/3 left-1/2 translate-y-1/2 -translate-x-1/2"
            >
              <div class="mt-3 w-full">
                <div
                  @click="seek($event)"
                  ref="progress"
                  class="h-1 bg-grey-dark cursor-pointer rounded-full bg-gray-500"
                >
                  <div
                    class="flex w-full justify-end h-1 bg-pink-600 rounded-full relative"
                    :style="{ width: step + '%' }"
                  ></div>
                </div>
                <div
                  class="flex w-full justify-end h-1 rounded-full relative"
                  :style="{ width: step + '%' }"
                >
                  <span
                    id="progressButtonTimer"
                    class="w-3 h-3 md:w-4 md:h-4 bg-white absolute pin-r pin-b -mb-1 rounded-full shadow"
                  ></span>
                </div>
              </div>
              <!-- timer and duration -->
              <duration-sound :duration="duration" :timer="timer" />
              <!-- audio play actions -->
              <div class="flex justify-center items-center mx-auto">
                <div class="w-full flex justify-between mx-auto">
                  <mobile-actions
                    @next="next"
                    @pause="pause"
                    @play="play"
                    @previous="previous"
                    :pauseTrack="pauseTrack"
                    :nextButton="nextButton"
                    :prevButton="prevButton"
                  />
                </div>
              </div>
              <!-- end of audio play actions -->
            </div>
          </div>

          <!-- songs lists on toggle -->

          <div
            class="rounded-lg h-full overflow-auto mt-6 scrolling"
            :class="showLists ? '' : 'hidden'"
          >
            <mobile-songs
              :audios="audios"
              :artist="artist"
              :index="index"
              :state="state"
              @select-sound="selectSound"
            />
          </div>

          <!-- end of mobile player actions -->

          <!-- mobile toggle songs list  -->
          <div class="relative mt-20">
            <toggel-songs
              @show-actions="showActions"
              @show-list="showList"
              @showLyrics="showLyrics"
              class="absolute bottom-3 inset-x-0 flex justify-around"
            />
          </div>
          <!-- mobile toggle songs list -->

          <!-- song inforation side for mobiel and desktop -->
        </div>
      </div>
    </div>

    <!-- audio player bottom start -->
    <div
      class="bg-gray-800 items-center absolute inset-x-0 md:bottom-0 bottom-[3.5rem] z-40 custom-width"
      :class="!toggle ? 'hidden md:block' : ''"
      style="background-color: #232b36"
    >
      <div class="mx-1">
        <!-- desktop and seeker -->
        <div class="w-full flex items-center">
          <div></div>
          <div class="mt-0 relative w-full">
            <div
              @click="seek($event)"
              ref="progress"
              class="h-1 bg-grey-dark cursor-pointer rounded-full bg-gray-500"
            >
              <div
                class="flex w-full justify-end h-1 bg-pink-600 rounded-full relative"
                :style="{ width: step + '%' }"
              ></div>
            </div>
            <div
              class="flex w-full justify-end h-1 rounded-full relative"
              :style="{ width: step + '%' }"
            >
              <span
                id="progressButtonTimer"
                class="w-3 h-3 md:w-4 md:h-4 bg-white absolute pin-r pin-b -mb-1 rounded-full shadow"
              ></span>
            </div>
          </div>
        </div>
        <!-- end of desktop and seeker -->

        <!-- mobile bottom player -->
        <div
          class="md:hidden flex justify-between items-center w-full relative"
        >
          <sm-actions
            class=""
            @pause="pause"
            @play="play"
            :loaded="loaded"
            :pauseTrack="pauseTrack"
            :currentTrack="currentTrack"
          />

          <div
            class="h-full flex items-center mr-2"
            @click="toggleDesktopPlayer"
          >
            <sm-songinfo :audios="audios" :index="index" :artist="artist" />
          </div>
        </div>
        <hr class="bg-green-700 opacity-20 mt-1 md:hidden" />
        <!-- end of mobile bottom player -->

        <div class="flex w-full items-center justify-between space-y-3 mx-auto">
          <!-- volum wraper -->
          <div class="md:w-2/12 items-center hidden md:flex">
            <!--le icon -->

            <div class="ml-3">
              <toggel-desktop
                @toggleDesktopPlayer="toggleDesktopPlayer"
                :hidePlayer="hidePlayer"
              />
            </div>
            <!-- end of toggle icon -->
            <mute-sound
              :mutePlayer="mutePlayer"
              :loaded="loaded"
              @mute="mute"
              class="ml-3"
            />
            <!-- valuom -->
            <div class="w-6/12 md:w-10/12 m-auto relative">
              <div
                @click="volume($event)"
                ref="volBar"
                class="h-1 bg-grey-dark cursor-pointer rounded-full bg-gray-500 m-auto relative"
                style="width: 100%"
              >
                <div
                  class="flex justify-end h-1 bg-pink-600 rounded-full relative"
                  :style="{ width: volumeProgress + '%' }"
                ></div>
              </div>
              <div
                class="flex justify-end h-1 rounded-full relative"
                :style="{ width: volumeProgress + '%' }"
              >
                <span
                  id="progressButtonVolume"
                  class="w-3 h-3 md:w-4 md:h-4 bg-white absolute pin-r pin-b -mb-1 rounded-full shadow"
                ></span>
              </div>
            </div>
            <!-- valuom -->
          </div>
          <!-- end of volum wraper -->

          <!--  buttons actions  -->
          <div class="flex justify-center items-center space-x-8 my-6">
            <desktop-actions
              @next="next"
              @pause="pause"
              @play="play"
              @previous="previous"
              :pauseTrack="pauseTrack"
              :nextButton="nextButton"
              :prevButton="prevButton"
              :loaded="loaded"
              :trackId="trackId"
              :currentTrack="currentTrack"
            />
          </div>
          <!-- end of buttons actions -->

          <!-- song data -->
          <song-details :audios="audios" :artist="artist" :index="index" />
          <!--  end of song data-->
        </div>
        <!-- bottom of the player -->
      </div>
    </div>
    <!-- end of audio player  -->
  </div>
</template>

<script>
import { ref, toRef, reactive, onMounted } from 'vue'
import { Howl, Howler } from 'howler'
import SongDetails from './SongDetails.vue'
import DesktopActions from './DesktopActions.vue'
import SmActions from './SmActions.vue'
import SmSonginfo from './SmSonginfo.vue'
import MobileActions from './MobileActions.vue'
import SongsList from './SongsList.vue'
import XsInfo from './XsInfo.vue'
import MobilesongToggel from './MobilesongToggel.vue'
import ToggelSongs from './ToggelSongs.vue'
import ToggelDesktop from './ToggelDesktop.vue'
import MobileSongs from './MobileSongs.vue'
import MuteSound from './MuteSound.vue'
import DurationSound from './DurationSound.vue'
import { useRecentStore } from '@/stores/recents'

export default {
  components: {
    SongDetails,
    DesktopActions,
    SmActions,
    SmSonginfo,
    MobileActions,
    SongsList,
    MobilesongToggel,
    XsInfo,
    ToggelSongs,
    ToggelDesktop,
    MobileSongs,
    MuteSound,
    DurationSound
  },
  props: ['audios', 'currentINDEX', 'artist', 'isPlaying'],

  data () {
    return {
      toggle: true,
      showLists: false,
      hidePlayer: false,
      showLyricsStatus:true,

      https: 'https://systech-abrof.s3.amazonaws.com/artists/'
    }
  },
  mounted () {
    /* eslint-disable */
    var sound = this.audios[this.index].howl

    var barWidth = (0.9 * 100) / 100
    this.sliderBtnVol =
      this.volBar.offsetWidth * barWidth + this.volBar.offsetWidth * 0.05 - 25
  },

  setup(props, { emit }) {
    const audios = toRef(props, 'audios')
    const isActive = toRef(props, 'isPlaying')
    const CurrentTrakID = toRef(props, 'currentINDEX')
    const step = ref(0)
    const trackId = ref('')
    const nextButton = ref(true)
    const prevButton = ref(true)
    const random = ref(false)
    const repeat = ref(false)
    const index = ref(0)
    const duration = ref('00:00')
    const timer = ref('00:00')
    const pauseTrack = ref(false)
    const progress = ref(null)
    const volBar = ref(null)
    const sliderBtn = ref(0)
    const sliderBtnVol = ref(null)
    const volumeProgress = ref(90)
    const mutePlayer = ref(false)
    const loaded = ref(false)
    const state = reactive({
      audioPlaying: []
    })
    const currentTrack = ref(null)

    function formatTime(secs) {
      var minutes = Math.floor(secs / 60) || 0
      var seconds = Math.floor(secs - minutes * 60) || 0

      return (
        (minutes < 10 ? '0' : '') +
        minutes +
        ':' +
        (seconds < 10 ? '0' : '') +
        seconds
      )
    }
    onMounted(() => {
      selectSound(CurrentTrakID.value)
    })

    function play(stopPlaying = true) {

      if (localStorage.getItem('status') && isActive) {
      if (stopPlaying) {
        Howler.stop()
      }
      
      var sound

      var audio = audios.value[index.value]
     currentTrack.value = audio;

     const recentsStore = useRecentStore();
     recentsStore.addRecentTrackAction(currentTrack.value);

      if (audio.howl) {
        sound = audio.howl
      } else {
        state.audioPlaying[index.value] = false
        sound = audio.howl = new Howl({
          src: [audio.full_url],
          autoplay: true,
          html5: true, // A live stream can only be played through HTML5 Audio.
          format: ['mp3', 'aac'],
          onplay: function () {
            loaded.value = true
            trackId.value = audio.id
            pauseTrack.value = true
            nextButton.value = true
            prevButton.value = true
            duration.value = formatTime(sound.duration())
            requestAnimationFrame(stepFunction.bind(this))
          },
          onpause: function () {
            pauseTrack.value = false
          },
          onend: function () {
            next()
          },
          onseek: function () {
            window.requestAnimationFrame(stepFunction.bind(this))
          }
        })
      }
      var id = sound.play()

      state.audioPlaying[index.value] = true
      }
    }


    function pause() {
      var audio = audios.value[index.value].howl

      if (audio) {
        audio.pause()
        pauseTrack.value = false
        state.audioPlaying[index.value] = false
      }
    }

    function stepFunction() {
      var sound = audios.value[index.value].howl

      var seek = sound.seek()
      timer.value = formatTime(Math.round(seek))
      step.value = (seek * 100) / sound.duration()
      if (!progress.value) return
      sliderBtn.value =
        progress.value.offsetWidth * (step.value / 100) +
        progress.value.offsetWidth * 0.05 -
        25

      if (sound.playing()) {
        window.requestAnimationFrame(stepFunction.bind(this))
      }
    }

    function seek(event) {
      var per = event.offsetX / progress.value.clientWidth

      if (per == 'Infinity') return
      var sound = audios.value[index.value].howl

      if (sound) {
        if (sound.playing()) {
          sound.pause()
          sound.seek(sound.duration() * per)
          var barWidth = (per * 100) / 100
          sliderBtn.value =
            progress.value.offsetWidth * barWidth +
            progress.value.offsetWidth * 0.05 -
            25
          console.log('mobile')
          sound.play()
        } else {
          console.log('mobile another way')
          sound.seek(sound.duration() * per)
          barWidth = (per * 100) / 100
          sliderBtn.value =
            progress.value.offsetWidth * barWidth +
            progress.value.offsetWidth * 0.05 -
            25
        }
      }
    }

    function next() {
      nextButton.value = false
      var audio = audios.value[index.value].howl

      state.audioPlaying[index.value] = false

      mutePlayer.value ? (mutePlayer.value = false) : ''
      audio && audio.mute(true) ? audio.mute(false) : ''

      if (audio && audios.value.length - 1 == index.value) {
        audio.stop()

        repeat.value
          ? /* eslint-disable */
            (index.value = index.value)
          : random.value
          ? (index.value = Math.floor(Math.random() * audios.value.length))
          : (index.value = 0)
      } else {
        if (audio) {
          audio.stop()
        }

        repeat.value
          ? (index.value = index.value)
          : random.value
          ? (index.value = Math.floor(Math.random() * audios.value.length))
          : index.value++
      }

      play()
    }

    function previous() {
      var audio = audios.value[index.value].howl
      prevButton.value = false
      state.audioPlaying[index.value] = false

      mutePlayer.value ? (mutePlayer.value = false) : ''
      audio && audio.mute(true) ? audio.mute(false) : ''

      if (!audio) {
        index.value = audios.value.length - 1
      } else if (audio && index.value == 0) {
        audio.stop()

        repeat.value
          ? (index.value = index.value)
          : random.value
          ? (index.value = Math.floor(Math.random() * audios.value.length))
          : (index.value = audios.value.length - 1)
      } else if (audio) {
        audio.stop()

        repeat.value
          ? (index.value = index.value)
          : random.value
          ? (index.value = Math.floor(Math.random() * audios.value.length))
          : index.value--
      }

      play()
    }
    function selectSound(indexSelected) {
      var audio = audios.value[index.value].howl

      if (audio) {
        audio.stop()
        state.audioPlaying[index.value] = false
      }

      index.value = indexSelected

      play()
    }

    function volume(event) {
      var per = event.layerX / parseFloat(volBar.value.scrollWidth)
      var barWidth = (per * 100) / 100
      volumeProgress.value = barWidth * 100
      sliderBtnVol.value =
        volBar.value.offsetWidth * barWidth +
        volBar.value.offsetWidth * 0.05 -
        25
      Howler.volume(per)
    }

    function mute() {
      var audio = audios.value[index.value].howl

      if (audio) {
        mutePlayer.value = !mutePlayer.value

        mutePlayer.value ? audio.mute(true) : audio.mute(false)
      }
    }

    return {
      play,
      pause,
      duration,
      formatTime,
      audios,
      pauseTrack,
      next,
      previous,
      index,
      timer,
      step,
      stepFunction,
      seek,
      selectSound,
      state,
      random,
      repeat,
      progress,
      volume,
      volBar,
      volumeProgress,
      sliderBtn,
      mute,
      mutePlayer,
      loaded,
      sliderBtnVol,
      nextButton,
      prevButton,
      trackId,
      currentTrack
    }
  },
  watch: {
    $route(to, from) {
      this.toggle = true
      this.hidePlayer = false
    }
  },
  methods: {
    showLyrics(){
        this.showLyricsStatus=!this.showLyricsStatus
    },
    toggleDesktopPlayer() {
      this.hidePlayer = !this.hidePlayer
      return (this.toggle = !this.toggle)
    },
    showActions() {
      return (this.showLists = false)
    },
    showList() {
      return (this.showLists = true)
    },
    getImage(artist) {
      if (artist) {
        return this.https + artist.cover
      } else {
        var images = require.context('@/assets/logo', false, /\.svg$/)
        return images('./' + 'main.svg')
      }
    }
  }
}
</script>

<style>
#journal-scroll::-webkit-scrollbar {
  width: 1px;
  cursor: pointer;
}

#journal-scroll::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
}

#journal-scroll::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #a0aec0;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.example::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.example {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.cd-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #f7fafc;
}

#progressButtonTimer,
#progressButtonVolume {
  margin-top: -9px;
  right: -8px;
}

@media screen and (max-width: 768px) {
  #progressButtonTimer,
  #progressButtonVolume {
    margin-top: -8px;
    right: -7px;
  }
}

.custom-width {
  max-width: 76.6%;
}

@media screen and (max-width: 959px) {
  .custom-width {
    max-width: 100%;
  }
}

@media screen and (min-width: 1299px) {
  .custom-width {
    max-width: 81.6%;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrolling::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrolling {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
</style>
