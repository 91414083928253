

import gql from "graphql-tag";
export const password = "Systech@7b7f1790606a9791bb75ad1f99df01cde32";
export const checkSubscription = gql`
mutation checkSubscription($msisdn: String!) {
  checkSubscription(msisdn: $msisdn) {
    success
    message
    code
    msisdn
    endSubDate
    user {
      phone
    }
    token
  }
}`


export const LOGIN = gql`
mutation Login($input: userInput) {
  login(input: $input) {
    code
    message
    success
    token
    user {
      id
      phone
    }
  }
}
`


export const unsubscribe = gql`
mutation Unsubscribe($msisdn: String!) {
  unsubscribe(msisdn: $msisdn) {
    code
    message
    msisdn
    success
  }
}
`


//--------------------------------------------start of user libiarary------------------------------------------------

export const getUserLib = gql`
    query userLib {
        me {
        favorite_playlists {
        id
        is_public
        name
        popularity
        tracks {
          cover
          id
        }
        }
        favorite_tracks {
          id
        name_ar
        full_url
        description_ar
        order
        single
        is_published
        artist {
        name_ar
        id
        cover
        }
        }
        playlists {
        id
        is_public
        name
        popularity
        tracks {
          cover
          id
        }
        }
        followings_list {
        cover
        id
        name_ar
        }
        }
        }
`


export const getPlayLists = gql`
query getPlaylists($page: Int, $limit: Int) {
  getPlaylists(page: $page, limit: $limit) {
    data {
      id
      name
      is_public
    }
  }
}
`

export const getPlaylist = gql`
query getPlaylists($getPlaylistId: ID!) {
  getPlaylist(id: $getPlaylistId) {
    id
    is_public
    name
    tracks {
      artist {
        id
        name_ar
        cover
      }
      id
      name_ar
      cover
      cover_full_url
      full_url
    }
  }
}
`

export const getFavorites = gql`
query Data($page: Int, $limit: Int) {
  getUserFavoriteTracks(page: $page, limit: $limit) {
    data {
      id
        name_ar
        full_url
        description_ar
        order
        lyrics
        single
        order
        is_published
        artist {
        name_ar
        id
        cover
        }
    }
  }
}
`

export const getRecentsTracks = gql`
  query getUserRecentTracks($page: Int, $limit: Int) {
  getUserRecentTracks(page: $page, limit: $limit) {
    data {
       id
        name_ar
        full_url
        description_ar
        order
        lyrics
        single
        order
        is_published
        artist {
        name_ar
        id
        cover
        }  
    }
    success
  }
}`

export const getFollowingsList = gql`

query Query($page: Int, $limit: Int) {
  getUserFollowingArtists(page: $page, limit: $limit) {
    data {
      id
      name_ar
      cover
    }
  }
}
`


export const isFollowedByUser = gql`
query IsUserFollowingThisArtist($artistId: String!) {
  isUserFollowingThisArtist(artistId: $artistId) {
    is_following_this_artist
  }
}
`