import { defineStore } from "pinia";
import apolloClient from "../plugins/apollo";
import { checkSubscription, unsubscribe } from "@/gqls/auth";
import { useTracksStore } from "./tracks";
import { Howler } from "howler";
export const useAuthStore = defineStore("auth", {
    state: () => {
        return {
            isLoggedin: Boolean, // check user status 
            user: Object, // user data to be stored 
            token: "",
            isAuthenticatedUser: false,
            status: localStorage.getItem("status"),

        }
    },// end of state
    getters: {
        getUser: (state) => state.user,
        isAuthenticated: (state) => state.isAuthenticatedUser,
        getPhone: (state) => state.user.phone,
        getStatus: (state) => state.status,


    }, //end of getters 
    actions: {
        /**
         * 
         * subscribe 
         */
        async subscribeAction() {
            window.location.href = "https://dsplp.sd.zain.com/?p=2382983983";

        },
      
        /**
         * checking user subscribtion status
         */
        async checkSubescriptionStatus(phone) {
            var phoneFormate = await this.phoneFormated(phone);

            return new Promise((resolve) => {
                apolloClient
                    .mutate({
                        mutation: checkSubscription,
                        variables: {

                            msisdn: phoneFormate
                        }
                    }).then(async response => {
                        this.user = response.data.checkSubscription.user,
                            this.isAuthenticatedUser = response.data.checkSubscription.success,

                            await this.setData(response)
                            , resolve(response);


                    })

            })



        },
        async phoneFormated(phone) {
            var newPhone;
            if (phone.startsWith("09")) {
                newPhone = phone.substring(1)
                return "249" + newPhone;
            } else if (phone.startsWith("2499")) {
                return phone;
            } else if (phone.startsWith("9")) {
                return "249" + phone;
            } else if (phone.startsWith("002499")) {
                newPhone = phone.substring(5);
                return "249" + newPhone;
            } else {
                return false;
            }
        },
        async setData(response) {
            /**
             * @todo check for response code 101 == user subscribed but doesn't have active subscrbtion
             */

            if (response.data.checkSubscription.code == "100") {

                window.localStorage.setItem('user', JSON.stringify(response.data.checkSubscription.user));
                window.localStorage.setItem('token', response.data.checkSubscription.token);
                window.localStorage.setItem('status', response.data.checkSubscription.success);
                this.status = localStorage.getItem('status');
            } else if (response.data.checkSubscription.code == "101") {
                this.wipeUserData(response)
            }

        },

        async unsubscribe() {
            let user = JSON.parse(localStorage.getItem('user'));
            if (user != null) {
                let phoneFormate = await this.phoneFormated(user.phone)

                return new Promise((resolve) => {
                    apolloClient
                        .mutate({
                            mutation: unsubscribe,
                            variables: {

                                msisdn: phoneFormate
                            }
                        }).then(async response => {
                            this.wipeUserData(response)
                                , resolve(response);


                        })

                })


            }
        },

        wipeUserData(response) {
            const player = useTracksStore();
            if (response.data?.checkSubscription?.code == "101" | response.data?.unsubscribe?.success | response.data?.unsubscribe?.message == "Not subscribed" | response.data?.unsubscribe?.message == "Unsubscribe successfully") {

                window.localStorage.removeItem("user"),
                    window.localStorage.removeItem("token"),
                    window.localStorage.removeItem("status"),
                    this.status = localStorage.getItem("status") ? true : false
                player.isPlaying = false,
                    Howler.stop()

            }
        },




        /**
         * logout the current authenticated user
         */
        async logOut() {
            const player = useTracksStore();

            await (window.localStorage.removeItem("user"),
                window.localStorage.removeItem("token"),
                window.localStorage.removeItem("status"),
                this.status = localStorage.getItem("status") ? true : false),
                player.isPlaying = false,
                Howler.stop()

        },



    }
})