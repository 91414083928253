<template>
  <div
    class="md:w-[183px] md:h-[270px] w-[120px] h-[190px] mx-3 overflow-hidden md:cursor-pointer relative"
  >
    <div class="rounded-t-md">
      <img
        ref="trackImage"
        :src="defaultImage"
        :data-src="getImage(track)"
        :alt="track.name_ar"
        class="object-cover w-full"
        @click="fetchTracks(track.id, collectionId)"
        width="100"
        height="100"
      />
    </div>

    <div class="">
      <div>
        <div class="flex justify-between pt-4">
          <span
            class="inline-block px-1 text-gray-100 text-right mr-2 md:text-xl text-lg font-bold truncate"
          >
            {{ track.name_ar }}</span
          >
        </div>
        <div class="flex justify-start pt-1 pb-2">
          <span
            class="inline-block px-1 text-gray-400 text-right mr-2 md:text-md text-sm font-bold"
            v-if="track.artist"
          >
            {{ track.artist.name_ar }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useTracksStore } from '@/stores/tracks'
import { mapState, mapActions } from 'pinia'
import { useFavoriteStore } from '@/stores/favorites'

export default {
  props: ['track', 'collectionId'],
  components: {},
  data () {
    return {
      https: 'https://systech-abrof.s3.amazonaws.com/artists/',
      defaultImage: require('@/assets/playlists/1.jpg'),
      observer: null
    }
  },
  computed: {
    ...mapState(useTracksStore, ['trackData'])
  },
  mounted () {
    this.observer = new IntersectionObserver(this.handleIntersection, {
      root: null,
      rootMargin: '0px',
      threshold: 0.5 // Adjust as needed
    })

    this.observer.observe(this.$refs.trackImage)
  },
  methods: {
    ...mapActions(useTracksStore, ['playcollection']),
    ...mapActions(useFavoriteStore, ['remove']),
    getImage (track) {
      if (track.artist && track.artist.cover) {
        return this.https + track.artist.cover
      } else {
        var images = require.context('@/assets/logo', false, /\.svg$/)
        return images('./' + 'main.svg')
      }
    },
    async removeFromFav () {
      await this.remove(this.track.id).then(response => {
        console.log(response.data)
      })
    },
    async fetchTracks (id, colectionID) {
      await this.playcollection(id, colectionID)
    },
    handleIntersection (entries) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const img = entry.target
          img.src = img.dataset.src // Add this line to set the src attribute
          this.observer.unobserve(img)
        }
      })
    }
  }
}
</script>

<style></style>
