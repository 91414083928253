import { useFavoriteStore } from "@/stores/favorites";
import { mapActions, mapState } from "pinia";

export default {
    computed: {
        ...mapState(useFavoriteStore, ["isFavorite"])
    },
    methods: {
        ...mapActions(useFavoriteStore, ["addFavoriteTrackAction", "removeFavoriteTrackAction"]),
        async addFavoriteTrack(track) {
            if (! localStorage.getItem('status'))return  this.$router.push('/login');
                if (this.isFavorite(track.id)) {
                    this.removeFavoriteTrackAction(track.id);
                } else {
                    this.addFavoriteTrackAction(track);
                }
           
           
        },
    },
}