import { defineStore } from "pinia";
import apolloClient from "../plugins/apollo";
import { getArtists, getArtist } from "@/gqls/artists";
export const useArtistsStore = defineStore("artists", {
    state: () => {
        return {
            artists: Array,
            artist: Object,
            nextPage: null,
            currentPage: 1,
            totalItems: 0

        }
    },
    getters: {
        artistsData: (state) => state.artists,
        artistData: (state) => state.artist,
        getCurrentPage: (state) => state.currentPage,
        getNextPage: (state) => state.nextPage,
        getTotal: (state) => state.totalItems

    },
    actions: {
        async showArtistsData(page = 1, limit = 4) {

            await apolloClient
                .query({
                    query: getArtists,

                    fetchPolicy: "cache-first",
                    variables: {

                        page: page,
                        limit: limit
                    },

                }).then(async res => {

                    this.artists = [...res.data.getArtists.data],

                        this.nextPage = res.data.getArtists.meta.next_page_url,
                        this.currentPage = res.data.getArtists.meta.current_page,
                        this.totalItems = res.data.getArtists.meta.total


                })
                .catch(error => {
                    this.error = error
                })
        },
        async showArtistsNextData(page, limit = 12) {
           
            await apolloClient
                .query({
                    query: getArtists,

                    fetchPolicy: "cache-first",
                    variables: {

                        page: page,
                        limit: limit
                    },

                }).then(async res => {
                    this.artists.push(...res.data.getArtists.data),
                        this.nextPage = res.data.getArtists.meta.next_page_url,
                        this.currentPage = res.data.getArtists.meta.current_page

                })
                .catch(error => {
                    this.error = error
                })

        },
        async showArtistData(id) {
            this.artist = null;
            await apolloClient
                .query({
                    query: getArtist,
                    variables: {
                        getArtistId: id
                    },

                    fetchPolicy: "cache-first",

                }).then(async res => {
                    this.artist = res.data.getArtist



                })
                .catch(error => {
                    this.error = error
                })
        },

    }
})