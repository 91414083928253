import { createRouter, createWebHistory } from 'vue-router';

import { routes } from './routes';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  /* eslint-disable */
  scrollBehavior(to, from, savedPosition) {
    let main = document.getElementById("main");
    main.scrollTop = -100;
  },
});



router.beforeEach(async (to, from, next) => {
  if (to.fullPath.includes("?msisdn=")) {
    return next({
      name: 'abrof.subscribe', params: {
        msisdn: to.query.msisdn,
      },
    });
  } else {
    next();
  }
});

export default router;
