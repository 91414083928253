<template>
  <div
    style="background-color: #9d2890"
    class="p-1 rounded-[15px]  flex items-center"
    v-if="getStatus=='undefined' || getStatus==false || getStatus==null"
  >
    <div class="flex justify-center mt-4">
      <button
        class="
         
          bg-pink-600
          mt-2
          w-60
          h-8
          rounded-[15px]
          text-gray-200
          text-center
          md:text-md
          text-sm
        "
        @click="subscribe"
      >
        اشترك الآن
      </button>
      <p class="text-center text-[12px] text-gray-200 my-2">
        اشترك الآن واستمتع بمزيج من أجمل الأغاني السودانية، اسمع كل جديد وحصري الفن السوداني باشتراك واحد وبدون
                إعلانات



      </p>
    </div>
  </div>
</template>

<script>
import { useAuthStore } from "@/stores/auth";
import { mapState ,mapActions} from "pinia";
export default {
    emits:["subscribe"],
    computed:{
      ...mapState(useAuthStore,['getStatus'])
    },
    
  watch: {
    getStatus: {
      immediate: true,
      deep: true,
      /* eslint-disable */
      handler(newValue, oldValue) {
        // Note: `newValue` will be equal to `oldValue` here
        // on nested mutations as long as the object itself
        // hasn't been replaced.
      },
    }},
    methods:{
      ...mapActions(useAuthStore, [
      "subscribeAction",
      "checkSubescriptionStatus",
    ]),
    async subscribe() {
      await this.subscribeAction();
    },
    }
  
};
</script>

<style>
</style>